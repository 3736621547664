@import '../../../scss/theme-bootstrap';

.meet-clinique-diagnostics-item-v1 {
  h3 {
    color: $color-text-black;
    font-size: 22px !important;
    line-height: 1 !important;
    margin: 0 0 5px 0 !important;
  }
  .subtitle {
    font-size: 15px;
    line-height: 1;
  }
  .button {
    @include swap_direction(margin, 15px 0 0 0);
  }
  .tout-image {
    text-align: center;
    img {
      display: block;
      margin: 0 auto;
      max-height: 100%;
      max-width: 100%;
    }
  }
}

.item .meet-clinique-diagnostics-item-v1 {
  padding: 0 5% 0 0;
  .tout-image {
    float: #{$ldirection};
    width: 65%;
  }
}

.item-last .meet-clinique-diagnostics-item-v1 {
  padding: 0 0 0 5%;
}
